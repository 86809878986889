import { Col, Container, Row } from "react-bootstrap";
import styled, { css } from "styled-components";

const Circle = styled.div`
    margin: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
`

const CircleText = styled.span`
    font-style: italic;
    font-size: ${props => props.special ? '15px' : '20px'};
    margin-left: 2vw;
    display:block;
    -webkit-transform: rotate(-45deg); 
    -moz-transform: rotate(-45deg); 
    transform: rotate(-45deg)
`

const Line = styled.div`
    margin: auto;
    position: relative;
    width: 5px;
    height: 25vh;
    background-color: white;
    ${props => props.special && css`
        height: 12.5vh;
    `}
`

export default function Timeline() {

    let left = [
        { text: 'Graduated with B.S. in Computer Science', gap: '10vh'},
        { text: 'George Mason University', gap: '33vh' },
        { text: 'Virginia Tech', gap: '20vh' },
        { text: 'Northern Virginia Community College', gap: '105vh'},
    ]

    let middle = ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017']

    let right = [
        { text: 'System Development/Support Assistant', subtext: 'George Mason University', gap: '26vh' },
        { text: 'Summer Team Impact Projects Intern', subtext: 'George Mason University', gap: '18vh' },
        { text: 'Software Developer Intern', subtext: 'DoLogic Inc.', gap: '45vh' }
    ]

    return (
        <Container id="timeline" style={{ paddingTop: '10vh'}}>
            <Row>
                <div className="text-center display-1" style={{ marginBottom: '10vh'}}>My Timeline</div>
            </Row>
            <Row>
                <Col lg={5}>
                    {left.map(item => {
                        return (
                            <div className="display-6 text-center" style={{ marginTop: item.gap}}>
                                {item.text}
                            </div>
                        )
                    })}
                </Col>
                <Col lg={2}>
                    {middle.map((item, index) => {
                        if (index === middle.length - 1) {
                            return (
                                <div><Circle><CircleText>{item}</CircleText></Circle></div>
                            )
                        }
                        else if (index === 0) {
                            return (
                                <div>
                                    <Circle><CircleText>{item}</CircleText></Circle>
                                    <Line special />
                                    <Circle><CircleText special>05/2023</CircleText></Circle>
                                    <Line special />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    <Circle><CircleText>{item}</CircleText></Circle>
                                    <Line />
                                </div>
                            )   
                        }      
                    })}

                </Col>
                <Col lg={5}>
                    {right.map(item => {
                        return (
                            <div className="text-center" style={{ marginTop: item.gap}}>
                                <div className="h2">{item.text}</div>
                                <div className="h5 font-italic">{item.subtext}</div>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </Container>
    )
}