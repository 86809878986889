import { Row, Container } from "react-bootstrap";

export default function Activities() {
    return (
        <Container id="#activities" fluid style={{ margin: '50vh 0'}}>
            <Row>
                <div className="display-1 text-center">Activities</div>
            </Row>
            <Row>
            </Row>
        </Container>
    )
}