import { Container, Row, Col } from "react-bootstrap";

export default function Projects() {
  const projects = [
    {
      title: "Archival Website",
      descriptions: [
        "Stack: Angular 13, Nodejs, Firebase",
        "Create Python Script to extract/clean raw data of 40,000 docs",
        "Build UI based on UX Design",
        "Configure Firebase for authentication and database access",
        "Weekly meeting for version control between dev team and UX team",
        "URL",
      ],
      url: "https://stip-demo.web.app/",
    },
    {
      title: "Simon App",
      descriptions: [
        "Framework: Flutter",
        "Target: Android 4.4+",
        "An app to help alert emegency contacts for the elderly",
        "Develop an intuitive UI for alarm setup",
        "Bridge between UI and Android native for SMS and GPS",
        "Accurate coordinates of the location when GPS is activated",
        "In beta testing phase",
      ],
    },
    { title: "Church Website", descriptions: ["Something", "Something"] },
    { title: "Credit Pro", descriptions: ["Something", "Something"] },
  ];

  return (
    <Container id="projects" fluid style={{ margin: "50vh 0" }}>
      <Row>
        <div className="display-1 text-center">Projects</div>
      </Row>
      <Row>
        {projects.map((project, index) => {
          return (
            <Col lg={6}>
              <div
                style={{
                  width: index % 2 === 0 ? "30vw" : "",
                  marginLeft: index % 2 === 0 ? "20vw" : "10vw",
                }}
              >
                <div class="display-6">{project.title}</div>
                <ul>
                  {project.descriptions.map((description) => (
                    <li key={description}>
                      {description.startsWith("URL") ? (
                        <a
                          className="text-decoration-none text-reset "
                          href={project.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u>{project.url}</u>
                        </a>
                      ) : (
                        description
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
