import { Col, Container, Row } from "react-bootstrap";
import { BsStarFill } from "react-icons/bs";

export default function Interests() {

    const skills = [
        {text: 'React', star: 4 },
        {text: 'Angular', star: 4},
        {text: 'ASP.NET Core', star: 4}, 
        {text: 'Nodejs', star: 4},
        {text: 'AWS', star: 4},
        {text: 'Flutter', star: 4}
    ]
    const softwares = [
        { text: 'Visual Studio Code', star: 4},
        { text: 'Visual Studio 2022', star: 4},
        { text: 'Microsoft SQL Server', star: 4},
        { text: 'MongoDB', star: 4},
        { text: 'Android Studio', star: 4}]

    return (
        <Container id="skills" fluid style={{ margin: '50vh 0'}}>
            <Row>
                <div className="display-1 text-center" style={{ margin: "10vh 0"}}>Area of Interests</div>
            </Row>
            <Row>
                <Col>
                    <div style={{ width: '30vw', marginLeft: '20vw'}}>
                        <div className="display-3 my-4">Skills</div>
                        <ul>
                            {skills.map(skill => <li key={skill.text}>{skill.text} {[...Array(skill.star)].map((e, i) => <span key={e}><BsStarFill /></span>)}</li>)}
                        </ul>   
                    </div>
                </Col>
                <Col>
                    <div style={{ marginLeft: '10vw'}}>
                        <div className="display-3 my-4">Softwares</div>
                        <ul>
                            {softwares.map(software => <li>{software.text} {[...Array(software.star)].map((e, i) => <span key={e}><BsStarFill /></span>)}</li>)}
                        </ul>   
                    </div>
                </Col>
            </Row>
        </Container>
    )
}