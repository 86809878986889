import { Container, Row } from "react-bootstrap";
import { BsGithub, BsLinkedin, BsMailbox } from "react-icons/bs";

export default function Contact() {

    const contacts = [
        { icon: <BsLinkedin />, to: 'https://www.linkedin.com/in/an-dang-98b361157/' },
        { icon: <BsMailbox />, to: 'mailto:adang22@gmu.edu' },
        { icon: <BsGithub />, to: 'https://github.com/dangan114' }
    ]

    return (
        <Container id="contact" fluid style={{ marginTop: '50vh', paddingBottom: '20vh'}}>
            <Row>
                <div className="display-1 text-center" style={{ margin: "5vh 0"}}>Contact</div>
                <div className="text-center">
                    {contacts.map(contact => <a className="text-decoration-none text-reset display-1 mx-4" href={contact.to} target="_blank" rel="noreferrer">{contact.icon}</a>)}
                </div>
            </Row>
        </Container>
    )
}