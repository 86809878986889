import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function Motion({ children }) {
    const control = useAnimation();
    const [ref, inView] = useInView();
  
    const boxVariant = {
      visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
      hidden: { opacity: 0, scale: 0 },
    };
  
    useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return (
        <motion.div
            ref={ref}
            variants={boxVariant}
            animate={control}
            initial="hidden"
        >{children}</motion.div>
    )
}