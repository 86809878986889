import { Col, Container, Image, Row } from "react-bootstrap";
import {
  BsEmojiSunglassesFill,
  BsMusicNoteList,
  BsCamera2,
  BsController,
} from "react-icons/bs";

function AboutMe() {
  let portrait = "/images/about-me.jpeg";
  let hobbies = [
    { text: "self-composed musician", icon: <BsMusicNoteList /> },
    { text: "self-opinionated foodie", icon: <BsEmojiSunglassesFill /> },
    { text: "self-obsessed gamer", icon: <BsController /> },
    { text: "self-claimed photographer", icon: <BsCamera2 /> },
  ];
  let colors = ["#ECDDD0", "#CED2C2", "#92B1B6", "#BFD1DF"];

  return (
      <Container id="about" style={{ margin: "50vh 0" }}>
        <Row>
          <Col lg={6} xs={12} className="display-1">
            <div style={{ width: "30vw", marginLeft: "20vw" }}>
              <div className="my-5 font-weight-bold">About Me</div>
              <div className="h4">I'm a self-taught software developer.</div>
              <div className="h4">
                I am also a
                <ul className="font-weight-bold">
                  {hobbies.map((hobbie, index) => (
                    <li style={{ color: colors[index] }} key={hobbie.text}>
                      {hobbie.text} {hobbie.icon}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <div style={{ marginLeft: "10vw" }}>
              <Image
                className="w-100 h-100"
                roundedCircle
                fluid
                src={portrait}
              />
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default AboutMe;
