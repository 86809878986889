import { Button, Col, Container, Row } from "react-bootstrap";
import { BsBoxArrowInRight } from "react-icons/bs";

export default function Welcome() {
    function viewResume() {
        var anchor = document.createElement('a');
        anchor.href = '/Resume_AnDang.pdf';
        anchor.target="_blank";
        anchor.click();
    }

  return (
    <Container id="welcome" style={{marginBottom: '50vh', paddingTop: '20vh'}}>
      <Row>
        <Col className="display-1 m-4">
          <div style={{ marginLeft: '20vw'}} className="w-50">
            <div>Welcome!</div>
            <div>I'm An Dang,</div>
            <div>I code and stuff.</div>
            <div>
              <Button
                style={{ margin: "2rem 0", fontSize: "1.5rem" }}
                variant="light"
                onClick={viewResume}
              >
                <BsBoxArrowInRight />
                    <span style={{ margin: "0 1rem" }}>my resume</span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
