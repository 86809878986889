import { Container, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { BsEmojiSmile } from 'react-icons/bs';
    
export default function CustomNavbar() {

    const navItems = [
        {
            name: 'About',
            link: '#about'
        }, 
        {
            name: 'Timeline',
            link: '#timeline'
        }, 
        {
            name: 'Skills',
            link: '#skills',
        }, 
        {
            name: 'Projects',
            link: '#projects',
        },
        { 
            name: 'Contact',
            link: '#contact'
        }]

    return (
        <Navbar bg="light" expand="lg" className="fixed-top">
            <Container>
                <Navbar.Brand><a className="text-decoration-none text-reset" href="#welcome"><BsEmojiSmile /></a></Navbar.Brand>
                {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                    <Nav className="justify-content-end" activeKey="/home">
                        {navItems.map(item => {
                            return (
                            <Nav.Item key={item.name}>
                                <Nav.Link href={item.link}><b className="h5">{item.name}</b></Nav.Link>
                            </Nav.Item>
                            ) 
                        })}
                    </Nav>
                {/* </Navbar.Collapse> */}
            </Container>
        </Navbar>
    )
}