import AboutMe from "../components/AboutMe";
import Activities from "../components/Activities";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Interests from "../components/Interests";
import Motion from "../components/Motion";
import Projects from "../components/Projects";
import Timeline from "../components/Timeline";
import Welcome from "../components/Welcome";

export default function Home() {

    return (
        <div style={{fontFamily: 'Garamond, serif'}}>
            <Header />
           
            <Motion><Welcome /></Motion>
    
            <Motion><AboutMe /></Motion>
    
            <Motion><Timeline /></Motion>  

            <Motion><Interests /> </Motion>

            <Motion><Projects /></Motion>

            <Motion><Activities /></Motion>

            <Motion><Contact /></Motion>

            <Footer />
        </div>
    )
}