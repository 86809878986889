import { Col, Container, Row } from "react-bootstrap"

export default function Footer() {
    return (
        <Container fluid className="bg-white text-dark">
            <Row style={{height: '10vh'}}>
                <Col>
                    <div class="h-100 d-flex align-items-center">
                        &copy; An Dang. All right reserved
                    </div>
                </Col>
                <Col>
                    <div class="h-100 d-flex align-items-center justify-content-end">
                        Additional Resources
                    </div>
                </Col>
            </Row>
        </Container>
    )
}